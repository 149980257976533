.round
  border-radius: 50%

.small
  object-fit: cover
  height: 40px
  width: 40px

.medium
  object-fit: cover
  height: 80px
  width: 80px

.normal
  object-fit: cover
  height: 125px
  width: 125px

.big
  object-fit: cover
  height: 160px
  width: 160px

.oversized
  object-fit: cover
  height: 200px
  width: 200px
